.pandobox-img {
    width: 100%;
    height: 100%;
}

.pandobox-container {
    overflow: hidden;
    width: 50%;
    margin: 0 auto;
}

.underline {
    background-color: #fff;
    margin-bottom: 30px;
    width: 100%;
}

.mint-button {
    width: 90%;
    margin: 0 auto;
}