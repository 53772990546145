.staking-content__title {
    display: flex;
    flex-direction: row;
}

.staking-content__title .blog-card__title {
    color: white;
    margin: 0;
}

.staking-content__container .mt-minus-10 {
    font-size: 16px;
}

.staking-bottom {
    justify-content: space-between;
}

.nft-container {
    padding: 0 !important;
}

.nft-container__img {
    width: 100%;
    overflow: hidden;
    position: relative;
}

.underline {
    width: 80%;
    background-color: #fff;
    margin: 0 auto;
}

.nft-container__top {
    position: absolute;
    top: 2px;
    left: 2px;
    display: flex;
    flex-direction: row;
}

.nft-container__mine {
    display: inline;
    padding: 1px;
    background-color: #ec1379;
    border-radius: 8px;
}

.nft-container__id {
    padding: 1px;
    background-color: #00000091;
    border-radius: 8px;
}

.nft-container__id p {
    color: white;
}

.nft-container__mine p {
    display: inline-block;
    color: white;
    font-size: 15px;
}

.mgt--3 {
    margin-top: -3px;
}

.nft-text__left {
    color: white;
    font-size: 12px;
}

.nft-text__right {
    color: #30C67E;
    font-size: 18px;
}

.nft-text__container {
    justify-content: space-between;
    width: 90%;
    margin: 10px auto;
}