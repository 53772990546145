

/* contest-details-section css end */
/* lottery-details css start */
.lottery-wrapper {
    padding: 100px 30px 50px 30px;
    background-color: #12023e;
}

@media (max-width: 991px) {
    .lottery-wrapper {
        padding: 90px 30px 50px 30px;
    }
}

@media (max-width: 575px) {
    .lottery-wrapper {
        padding: 70px 30px 50px 30px;
    }
}

.lottery-wrapper__title {
    font-size: 60px;
    text-transform: uppercase;
    font-weight: 700;
    color: #ffffff;
    text-align: center;
    margin-bottom: 5px;
}

@media (max-width: 991px) {
    .lottery-wrapper__title {
        font-size: 48px;
    }
}

@media (max-width: 767px) {
    .lottery-wrapper__title {
        font-size: 36px;
    }
}

@media (max-width: 575px) {
    .lottery-wrapper__title {
        font-size: 32px;
    }
}

.lottery-wrapper p {
    text-align: center;
}

.lottery-wrapper__btn {
    text-align: center;
    margin-top: 45px;
}

.lottery-wrapper.style--two {
    padding: 0;
    background-color: transparent;
}

.lottery-wrapper .action-header {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    align-items: center;
    padding: 50px 40px;
    background-color: #5a4bcc;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}

@media (max-width: 991px) {
    .lottery-wrapper .action-header {
        padding: 30px;
    }
}

.lottery-wrapper .action-header .left {
    width: 50%;
}

@media (max-width: 1199px) {
    .lottery-wrapper .action-header .left {
        width: 41%;
    }
}

@media (max-width: 991px) {
    .lottery-wrapper .action-header .left {
        width: 100%;
    }
}

.lottery-wrapper .action-header .left ul {
    margin: -5px -10px;
}

.lottery-wrapper .action-header .left ul li {
    display: inline-block;
    margin: 5px 10px;
}

.lottery-wrapper .action-header .right {
    width: 50%;
}

@media (max-width: 1199px) {
    .lottery-wrapper .action-header .right {
        width: 59%;
    }
}

@media (max-width: 991px) {
    .lottery-wrapper .action-header .right {
        width: 100%;
        margin-top: 15px;
    }
}

.lottery-wrapper .action-header .right ul {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin: -5px -10px;
    justify-content: flex-end;
}

@media (max-width: 991px) {
    .lottery-wrapper .action-header .right ul {
        justify-content: flex-start;
    }
}

.lottery-wrapper .action-header .right ul li {
    display: flex;
    margin: 5px 10px;
}

.lottery-wrapper .action-header .right ul li i {
    color: #ffffff;
    font-size: 28px;
    padding-right: 10px;
}

.lottery-wrapper .action-header .right ul li button {
    background-color: transparent;
    display: flex;
    align-items: center;
}

.lottery-wrapper .action-header .right ul li button span {
    color: #ffffff;
}

.clock2 {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    align-items: center;
    margin: -5px -8px;
}

.clock2 div {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    align-items: center;
    margin: 5px 8px;
}

.clock2 div span {
    color: #ffffff;
}

.clock2 div p {
    color: #ffffff;
}

.lottery-single {
    background-color: #5a4bcc;
    position: relative;
}

.lottery-single:hover .lottery-single__close {
    opacity: 1;
    visibility: 1;
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
}

.lottery-single__close {
    position: absolute;
    width: 30px;
    height: 30px;
    background-color: #5a4bcc;
    color: #ffffff;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    top: -15px;
    right: -15px;
    opacity: 0;
    visibility: 0;
    -webkit-transform: scale(0.5);
    -ms-transform: scale(0.5);
    transform: scale(0.5);
    -webkit-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}

.lottery-single__header {
    background-color: #4939c1;
    padding: 20px 20px;
}

.lottery-single__header ul {
    padding-left: 0;
}

.lottery-single__header .titcket-number {
    color: #ffffff;
    margin-bottom: 10px;
}

.lottery-single__header .top {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 10px;
    margin-bottom: 20px;
    border-bottom: 1px solid #6d62c7;
}

.lottery-single__header .top .titcket-number {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 0;
}

.lottery-single__header .top ul {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    align-items: center;
}

.lottery-single__header .top ul li button {
    background-color: transparent;
    display: flex;
    align-items: center;
}

.lottery-single__header .top ul li button span {
    color: #ffffff;
}

.lottery-single__header .top ul li button i {
    font-size: 28px;
    color: #ffffff;
}

.lottery-single__selected-number {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin: -3px;
    justify-content: space-around;
}

.lottery-single__selected-number li {
    width: 36px;
    height: 36px;
    background-image: linear-gradient(135deg, #b122e6 0%, #ff63de 100%);
    box-shadow: 0px 19px 56px 0px rgba(0, 0, 0, 0.2);
    color: rgba(255, 255, 255, 0.4);
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    margin: 3px;
}

.lottery-single__selected-number li.active {
    background-image: linear-gradient(86deg, #ec038b 0%, #fb6468 44%, #fbb936 100%);
    box-shadow: 0px 17px 40px 0px rgba(124, 78, 25, 0.35);
    border: none;
    color: #ffffff;
}

.lottery-single__number {
    flex-direction: column;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin: -3px;
}

.lottery-single__number li {
    width: 36px;
    height: 36px;
    border: 1px solid rgba(255, 255, 255, 0.4);
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    cursor: pointer;
    margin: 4px 3px;
}

.lottery-single__number li.active {
    background-image: linear-gradient(86deg, #ec038b 0%, #fb6468 44%, #fbb936 100%);
    box-shadow: 0px 17px 40px 0px rgba(124, 78, 25, 0.35);
    border: none;
}

.lottery-single__body {
    display: flex;
    justify-content: space-around;
    padding: 20px 20px;
}

.lottery-single__body ul {
    padding-left: 0;
}

.lottery-single__footer {
    margin-left: 20px;
    margin-right: 20px;
    padding: 20px 0 30px 0;
    border-top: 1px solid #7d73cd;
}

.lottery-single.style--two .lottery-single__number {
    justify-content: flex-start;
    margin: -4px;
}

@media (max-width: 575px) {
    .lottery-single.style--two .lottery-single__number {
        justify-content: center;
    }
}

.lottery-single.style--two .lottery-single__number li {
    margin: 4px 4px;
}

.buy-lottery-ticket {
    border: 1px solid #201456;
    padding: 30px 30px;
    border-radius: 25px;
    -webkit-border-radius: 25px;
    -moz-border-radius: 25px;
    -ms-border-radius: 25px;
    -o-border-radius: 25px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    align-items: center;
}

.buy-lottery-ticket .left {
    width: 35%;
}

@media (max-width: 991px) {
    .buy-lottery-ticket .left {
        width: 60%;
    }
}

@media (max-width: 767px) {
    .buy-lottery-ticket .left {
        width: 100%;
    }
}

.buy-lottery-ticket .right {
    width: 65%;
    text-align: right;
}

@media (max-width: 991px) {
    .buy-lottery-ticket .right {
        width: 40%;
    }
}

@media (max-width: 767px) {
    .buy-lottery-ticket .right {
        width: 100%;
        margin-top: 30px;
        text-align: center;
    }
}

.buy-lottery-ticket .sub-total-price, .buy-lottery-ticket .total-price {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
}

.buy-lottery-ticket .sub-total-price span, .buy-lottery-ticket .total-price span {
    font-size: 18px;
}

.buy-lottery-ticket .sub-total-price {
    padding-bottom: 20px;
}

.buy-lottery-ticket .total-price {
    padding-top: 20px;
    border-top: 1px solid #201456;
}

.buy-lottery-ticket .total-price p, .buy-lottery-ticket .total-price span {
    color: #ffffff;
}

.pick-lottery-package .title {
    color: #ffffff;
    font-weight: 700;
    font-size: 60px;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 30px;
}

@media (max-width: 991px) {
    .pick-lottery-package .title {
        font-size: 48px;
    }
}

@media (max-width: 767px) {
    .pick-lottery-package .title {
        font-size: 36px;
    }
}

@media (max-width: 575px) {
    .pick-lottery-package .title {
        font-size: 32px;
    }
}

.lottery-package-list {
    text-align: center;
    margin: -10px -15px;
}

.lottery-package-list a {
    padding: 20px 20px;
    background-image: linear-gradient(0deg, #e9388e 0%, #b9217a 43%, #880965 100%);
    color: #ffffff;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    margin: 10px 15px;
}

.lottery-package-list a:hover {
    background-image: linear-gradient(86deg, #ec038b 0%, #fb6468 44%, #fbb936 100%);
    box-shadow: 0px 17px 40px 0px rgba(124, 78, 25, 0.35);
}

/* lottery-details css end */
/* cart section css start */
.cart-wrapper {
    padding: 120px 30px 60px 30px;
    background-color: #12023e;
}

@media (max-width: 991px) {
    .cart-wrapper {
        padding-top: 90px;
    }
}

@media (max-width: 575px) {
    .cart-wrapper {
        padding-top: 80px;
    }
}

.cart-wrapper__title {
    font-weight: 700;
    font-size: 60px;
    text-align: center;
    margin-bottom: 50px;
}

@media (max-width: 1199px) {
    .cart-wrapper__title {
        font-size: 56px;
    }
}

@media (max-width: 991px) {
    .cart-wrapper__title {
        font-size: 48px;
    }
}

@media (max-width: 767px) {
    .cart-wrapper__title {
        font-size: 36px;
    }
}

.ticket-wrapper__header {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    justify-content: space-between;
    background-color: #4939c1;
    padding: 30px 20px;
}

.ticket-wrapper__header button {
    font-size: 14px;
    text-transform: uppercase;
    background-color: transparent;
    color: #ffffff;
}

.ticket-wrapper__body {
    background-color: #5a4bcc;
    padding: 20px 20px;
}

.ticket-wrapper__body .single-row {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 20px 0;
    border-bottom: 1px solid #8a80d9;
}

.ticket-wrapper__body .single-row:first-child {
    padding-top: 0;
}

.ticket-wrapper__body .single-row:last-child {
    padding-bottom: 0;
    border-bottom: none;
}

.ticket-wrapper__body .single-row .numbers {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin: -3px;
}

@media (max-width: 575px) {
    .ticket-wrapper__body .single-row .numbers {
        width: 100%;
        justify-content: center;
    }
}

.ticket-wrapper__body .single-row .numbers li {
    margin: 3px;
    width: 36px;
    height: 36px;
    background-image: linear-gradient(86deg, #ec038b 0%, #fb6468 44%, #fbb936 100%);
    box-shadow: 0px 17px 40px 0px rgba(124, 78, 25, 0.35);
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    color: #ffffff;
}

.ticket-wrapper__body .single-row .action-btns {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin: -5px -10px;
}

@media (max-width: 575px) {
    .ticket-wrapper__body .single-row .action-btns {
        width: 100%;
        margin-top: 15px;
        justify-content: center;
    }
}

.ticket-wrapper__body .single-row .action-btns button {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    font-size: 22px;
    margin: 5px 10px;
}

.ticket-wrapper__body .single-row .action-btns button:hover {
    background-position: 120% 50%;
}

.ticket-wrapper__body .single-row .action-btns .edit-btn {
    background-image: linear-gradient(7deg, #ec1379 0%, #6c0092 100%);
    box-shadow: 0px 7px 7px 0px rgba(163, 15, 110, 0.35);
}

.ticket-wrapper__body .single-row .action-btns .del-btn {
    background-image: linear-gradient(-45deg, #c165dd 0%, #d10f7e 100%);
    box-shadow: 0px 7px 7px 0px rgba(209, 15, 126, 0.35);
}

.checkout-wrapper__header {
    padding: 30px 20px 30px 20px;
    background-color: #4939c1;
}

.checkout-wrapper__body {
    padding: 20px;
    background-color: #5a4bcc;
}

.checkout-wrapper__body .price li {
    padding: 30px 0;
    border-bottom: 1px solid #867cd5;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    justify-content: space-between;
}

.checkout-wrapper__body .price li:first-child {
    padding-top: 0;
}

.checkout-wrapper__body .price li:last-child {
    padding-bottom: 0;
    border-bottom: none;
}

.checkout-wrapper__body .price li .caption + span {
    color: #24e980;
    font-size: 15px;
}

.checkout-wrapper__body .price li .price {
    font-size: 22px;
    color: #ffffff;
}

.checkout-wrapper__btn {
    text-align: center;
    margin-bottom: -40px;
    margin-top: 50px;
}
